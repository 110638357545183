import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../app/api/axios';
import { Autocomplete, TextField } from '@mui/material';

const OwnerCardsAutoComplete = ({ setValue, value }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetchOptions = async (query) => {
    try {
      const response = await axiosInstance.get('/user-phones-autocomplete', {
        params: {
          query: query,
          type: 'search',
        },
      });

      setOptions(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        getOptionLabel={(option) => `${option.name} - ${option.number}`} // Modify this to show both name and number
        renderOption={(props, option) => (
          <li {...props}>
            {option.name} - {option.number} {/* Custom render of both name and number */}
          </li>
        )}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};

export default OwnerCardsAutoComplete;
