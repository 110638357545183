import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { Skeleton } from '@mui/material';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaLongArrowAltUp, FaLongArrowAltDown, FaCopy } from "react-icons/fa";
import moment from 'moment';

const MyOrdersHome = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])

    const getData = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/user-orders')
        setItems(data)
        setLoading(false)
    }
    const success = () => {
        Swal.fire({
          icon: 'success',
          text: intl.formatMessage({ id: 'Table.text_copy' }),
          showConfirmButton: false,
          timer: 1500
        });
      }
    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }),
            accessor: 'id',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    # {row.original.id}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Card' }),
            accessor: row => `${row.card?.name} ${row.card?.number}`,
            Cell: row => (
                row.row.original.card &&
                <div className='d-flex align-items-center ps-3'>
                    <Images img={row.row.original.card.img} name={row.row.original.card.code} type='phone' />
                    <div className='flex-grow-1'>
                        <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                            {row.row.original.card.name}
                        </div>
                        <span className='text-muted d-block fw-semibold text-start'>{row.row.original.card.number}</span>
                    </div>
                </div>
            ),
            filter: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const card = row.values[id];
                    return (
                        card.toLowerCase().includes(filterValue.toLowerCase())
                    );
                });
            }
        },
        {
            Header: intl.formatMessage({ id: 'Table.usdt' }),
            accessor: 'usdt',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.usdt}$
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.to_number' }),
            accessor: 'to_number',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.to_number}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Amount' }),
            accessor: 'amount',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.amount} EGP
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Type' }),
            accessor: 'type',
            Cell: ({ row }) => (
                <div className={`w-100 flex-start ${row.original.type == 'buy' ? 'text-success' : 'text-danger'}`}>
                    {row.original.type === 'buy' ? 'Send Cash' : ' Recive Cash'}
                    {row.original.type === 'buy' ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}
                    <div>
                        {row.original.type === 'buy' ? 'Buy USDT' : 'Sell USDT'}
                    </div>
                </div>
            )
        },

        {
            Header: intl.formatMessage({ id: 'Table.Status' }),
            accessor: 'status',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.status == 'pending' && <span className="badge badge-light-warning">{row.original.status}</span>}
                    {row.original.status == 'canceled' && <span className="badge badge-light-danger">{row.original.status}</span>}
                    {row.original.status == 'completed' && <span className="badge badge-light-success">{row.original.status}</span>}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Date' }),
            Cell: row => (
                <div className="d-flex flex-center my-2">
                    {moment(row.row.original.created_at).format('hh:mm A')}
                    <br /> {moment(row.row.original.created_at).format('YYYY-MM-DD')}
                </div>
            ),
        },
        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: row => (
                <div className="d-flex flex-center my-2">
                    <Link to={`/my-orders-detail/${row.row.original.id}`} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Table.View' })} </Link>
                    <button onClick={(e) => [navigator.clipboard.writeText('*9*7*'+row.row.original.to_number+'*'+row.row.original.amount+'#'), success()]} type='button' className='btn w-100 btn-primary' >
                        <FaCopy className='me-2' />
                    </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getData()
    }, [])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
        >
            <div className="mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        {intl.formatMessage({ id: 'Menu.Orders' })}
                    </h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.Home' })}
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.MyOrders' })}
                        </li>
                    </ul>
                </div>
            </div>
            {!loading ?
                <ReactTable columns={columns} data={items} customComponent={null} />
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }
        </motion.nav>

    )
}

export default MyOrdersHome