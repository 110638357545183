import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../api/axios'
import { OwnerChartMonth } from '../../../../_metronic/partials/widgets/DashboardCharts/OwnerChartMonth';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { FormControl, MenuItem, Select, Skeleton } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';
import { FaArrowDown, FaArrowUp, FaBitcoin, FaCheckCircle, FaDollarSign, FaFire } from 'react-icons/fa';

const OwnerDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [tab_btn, setTab_btn] = useState('this_week');
    const intl = useIntl()

    const types = [
        // { id: 'today', name: 'today' },
        // { id: 'yesterday', name: 'yesterday' },
        { id: 'this_week', name: 'this week' },
        { id: 'last_week', name: 'last week' },
        { id: 'this_month', name: 'this month' },
        { id: 'last_month', name: 'last month' },
        { id: 'this_year', name: 'this year' },
    ]
    const handle_tabs = async (type) => {
        setTab_btn(type)
    }

    const [data, setData] = useState(null);
    const get_data = async () => {
        const { data } = await axiosInstance.get('/owner-dashboard', {
            params: { 'type': tab_btn },
        })
        setData(data)
    }

    useEffect(() => {
        get_data()
    }, [tab_btn])
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Analytics' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Analytics' })}</li>
                    </ul>

                </div>
                <div className="col-3">
                    <div className='card  col-md-12 my-3 col-sm-12' style={{ borderRadius: '8px' }}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="type"
                                onChange={(e) => { setTab_btn(e.target.value) }}
                                value={tab_btn}
                            >
                                {types.map((row, key) => (
                                    <MenuItem key={key} value={row.id}>
                                        {row.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>

            {data ?
                <>
                    <div className="row">
                        <div className="card">
                            <div className="row flex-between">
                                <div className='col-4'>
                                    <div className='row align-items-center mt-5 ' style={{ borderRight: ' solid 0.5px #d8e2ef' }}>
                                        <div>
                                            <span className='dahsboard_first_section_div' style={{ backgroundColor: '#e6effc' }}>
                                                <FaArrowDown style={{ color: '#2c7be5' }} />
                                            </span>
                                            <span className="text-black-500 pt-1 ms-1 fw-semibold fs-6"> Sell Avarage</span>
                                        </div>
                                        <p className="new_font fs-1 lh-1 ms-2 my-4 text-gray-700  "> {data?.sell_average.toFixed(2) ?? 0} EGP</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='row align-items-center mt-5 ' style={{ borderRight: ' solid 0.5px #d8e2ef' }}>
                                        <div>
                                            <span className='dahsboard_first_section_div' style={{ backgroundColor: '#e5f7ff' }}>
                                                <FaArrowUp style={{ color: '#27bcfd' }} />
                                            </span>
                                            <span className="text-black-500 pt-1 ms-1 fw-semibold fs-6"> Buy Avarage</span>
                                        </div>
                                        <p className="new_font fs-1 lh-1 ms-2 my-4 text-gray-700  "> {data?.buy_average.toFixed(2) ?? 0} EGP</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='row align-items-center mt-5 ' >
                                        <div>
                                            <span className='dahsboard_first_section_div p-0 fs-1' >
                                                <FaCheckCircle style={{ color: '#00d27a' }} />
                                            </span>
                                            <span className="text-black-500 pt-1 ms-1 fw-semibold fs-6"> Total Orders</span>
                                        </div>
                                        <p className="new_font fs-1 lh-1 ms-2 my-4 text-gray-700  "> {data?.total_orders ?? 0} Order</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="card">
                            <div className="row flex-between">
                                <div className='col-4'>
                                    <div className='row align-items-center mt-5 ' style={{ borderRight: ' solid 0.5px #d8e2ef' }}>
                                        <div>
                                            <span className='dahsboard_first_section_div p-0 fs-1'>
                                                <FaFire style={{ color: '#2c7be5' }} />
                                            </span>
                                            <span className="text-black-500 pt-1 ms-1 fw-semibold fs-6"> Cash</span>
                                        </div>
                                        <p className="new_font fs-1 lh-1 ms-2 my-4 text-gray-700  "> {data?.cash ?? 0} EGP</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='row align-items-center mt-5 ' style={{ borderRight: ' solid 0.5px #d8e2ef' }}>
                                        <div>
                                            <span className='dahsboard_first_section_div p-0 fs-1' >
                                                <FaBitcoin style={{ color: '#27bcfd' }} />
                                            </span>
                                            <span className="text-black-500 pt-1 ms-1 fw-semibold fs-6"> Coins</span>
                                        </div>
                                        <p className="new_font fs-1 lh-1 ms-2 my-4 text-gray-700  "> {data?.coins ?? 0} USDT</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='row align-items-center mt-5 ' >
                                        <div>
                                            <span className='dahsboard_first_section_div p-0 fs-1'>
                                                <FaDollarSign style={{ color: '#00d27a' }} />
                                            </span>
                                            <span className="text-black-500 pt-1 ms-1 fw-semibold fs-6"> Net Profit</span>
                                        </div>
                                        <p className="new_font fs-1 lh-1 ms-2 my-4 text-gray-700  "> {data?.net_profit ?? 0} EGP</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <OwnerChartMonth names={data.names} numbers={data.numbers} />
                    </div>
                </>
                :
                <div className="row">
                <div className="col-12">
                  <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                </div>
              </div>
            }
        </motion.nav>
    )
}

export default OwnerDashboard