import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { KTSVG } from '../../../../_metronic/helpers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import OwnerUsersAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/OwnerUsersAutoComplete';
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import useAuthContext from '../../../Auth/AuthContext';

export const AddOrder = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const [user_value, setUSER_Value] = useState(null);
  const [Step, setStep] = useState(0);
  const [Cards, setCards] = useState([]);
  const { user } = useAuthContext();
  const [types, setTypes] = useState([
    { id: 'buy', name: 'Buy USDT' },
    { id: 'sell', name: 'Sell USDT' },
  ]);

  const [data, setData] = useState({
    type: 'buy',
    to_number: '',
    amount: 0,
    taxes: 0,
    price: 0,
    card_id: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('user_id', user_value.id);
    try {
      await axiosInstance.post(`/owner-orders`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false)
        setErrors([])
        window.location.href = '/Order'
        // navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }


  const SearchForCards = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      await axiosInstance.post(`/owner-search-for-cards`, formData).then(response => {
        const cards = response.data;
        console.log(cards.length);
        if (cards.length === 0) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "No cards found",
          });
        }
        else {
          setCards(cards)
          setStep(1)
        }
        setLoading(false)
        setErrors([])
      })
    } catch (e) {
      // if (e.response.status === 422) {
      //   setLoading(false)
      //   setErrors(e.response.data.errors)
      // }
    }
  }

  const formatPercentage = (value, total) => {
    if (total === 0) return "0.00%";
    return ((value / total) * 100).toFixed(2);
  };

  const handle_select = (item) => {
    setData({
      ...data,
      card_id: item.id,
    })
  };

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }),
      accessor: 'id',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          # {row.original.id}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.User' }),
      accessor: row => `${row.name} ${row.number}`,
      Cell: row => (
        row.row.original &&
        <div className='d-flex align-items-center ps-3'>
          <Images img={row.row.original.img} name={row.row.original.name} />
          <div className='flex-grow-1'>
            <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
              {row.row.original.name}
            </div>
            <span className='text-muted d-block fw-semibold text-start'>{row.row.original.number}</span>
          </div>
        </div>
      ),
      filter: (rows, number, filterValue) => {
        return rows.filter(row => {
          const user = row.values[number];
          return (
            user.toLowerCase().includes(filterValue.toLowerCase())
          );
        });
      }
    },
    {
      Header: intl.formatMessage({ id: 'Table.cashbalance' }),
      accessor: 'balance',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.balance}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Send' }),
      accessor: 'Send',
      Cell: row => (
        <div className=" flex-center w-200" >
          <div className='d-flex align-items-center flex-column mt-3 p-3 bg-primary border-r-8 w-100' >
            <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
              <span> {intl.formatMessage({ id: 'Table.daysendbalance' })} </span>
              <span>
                {formatPercentage(row.row.original.day_send_balance, user.business.day_send_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.day_send_balance, user.business.day_send_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.day_send_balance, user.business.day_send_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.day_send_balance}
              ></div>
            </div>

            <div className=' d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-5 mb-2'>
              <span > {intl.formatMessage({ id: 'Table.monthsendbalance' })} </span>
              <span>
                {formatPercentage(row.row.original.month_send_balance, user.business.month_send_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.month_send_balance, user.business.month_send_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.month_send_balance, user.business.month_send_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.month_send_balance}
              ></div>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.Receive' }),
      accessor: 'Receive',
      Cell: row => (
        <div className=" flex-center w-200" >
          <div className='d-flex align-items-center flex-column mt-3 p-3 bg-danger border-r-8 w-100' >
            <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
              <span> {intl.formatMessage({ id: 'Table.dayreceivebalance' })} </span>
              <span>
                {formatPercentage(row.row.original.day_receive_balance, user.business.day_receive_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.day_receive_balance, user.business.day_receive_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.day_receive_balance, user.business.day_receive_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.day_receive_balance}
              ></div>
            </div>

            <div className=' d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-5 mb-2'>
              <span > {intl.formatMessage({ id: 'Table.monthreceivebalance' })} </span>
              <span>
                {formatPercentage(row.row.original.month_receive_balance, user.business.month_receive_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.month_receive_balance, user.business.month_receive_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.month_receive_balance, user.business.month_receive_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.month_receive_balance}
              ></div>
            </div>
          </div>
        </div>
      ),
    },

    {

      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: row => (
        <div className="d-flex flex-center my-2">
          <button type='button' disabled={data.card_id && data.card_id === row.row.original.id}
            onClick={(e) => handle_select(row.row.original)}
            className='btn me-2 w-100 btn-light-info'
          >
            {data.card_id && data.card_id === row.row.original.id
              ? intl.formatMessage({ id: 'Table.Selected' })
              : intl.formatMessage({ id: 'Table.Select' })}
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if(data.to_number.length > 5 && data.amount > 0){
      const firstThreeNumbers = data.to_number.slice(0, 3);
      if(firstThreeNumbers === '010' || firstThreeNumbers === '0100' ){
        setData((prevData) => ({ ...prevData, 'taxes': 1  }));
      }
      else{
        let fees = Number(data.amount) * 0.005
        if(fees > 15){
          fees = 15;
        }
        setData((prevData) => ({ ...prevData, 'taxes': fees.toFixed(2)  }));
      }
    }
  },[data.to_number , data.amount])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Orders.Create' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Orders' })}</li>
          </ul>
        </div>
        <div>
          <button onClick={() => navigate(-1)} type='button' className='btn btn-primary' >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Form.GoBack' })}
          </button>
        </div>
      </div>
      <div id="kt_app_content_container" className="app-container container-xxl">
        <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                </div>
              </div>

              <div className="card-body pt-0">
                <div className="row flex-between my-5">

                  {/* User */}
                  <div className="my-3 fv-row col-6 fv-plugins-icon-container">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.User' })}</label>
                    <OwnerUsersAutoComplete value={user_value} setValue={setUSER_Value} />
                    {errors.type &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.type}</span>
                        </div>
                      </div>
                    }
                  </div>

                  {/* Type */}
                  <div className="my-3 fv-row col-6 fv-plugins-icon-container">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.OrderType' })}</label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="type"
                        onChange={handleChange}
                        value={data.type}
                      >
                        {types.map((row, key) => (
                          <MenuItem key={key} value={row.id}>
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.type &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.type}</span>
                        </div>
                      </div>
                    }
                  </div>



                  {/* Amount */}
                  <div className={`my-3 fv-row ${data.type === 'buy' ? 'col-4' : 'col-6'}  fv-plugins-icon-container`}>
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.Price' })}</label>
                    <input type="number" name="price" className="form-control py-4" value={data.price} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Price' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.price &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.price}</span>
                        </div>
                      </div>
                    }
                  </div>

                  {/* Price */}
                  <div className={`my-3 fv-row ${data.type === 'buy' ? 'col-4' : 'col-6'}  fv-plugins-icon-container`}>
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.Amount' })}</label>
                    <input type="number" name="amount" className="form-control py-4" value={data.amount} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Amount' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.amount &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.amount}</span>
                        </div>
                      </div>
                    }
                  </div>

                  {/* Tax */}
                  {data.type === 'buy' &&
                    <>
                      <div className="my-3 fv-row col-4 fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Transfer_taxes' })}</label>
                        <input readOnly type="number" name="taxes" className="form-control py-4" value={data.taxes} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Transfer_taxes' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.taxes &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.taxes}</span>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="my-3 fv-row col-12 fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.ToNumber' })}</label>
                        <input type="text" name="to_number" className="form-control py-4" value={data.to_number} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.ToNumber' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.to_number &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.to_number}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </>
                  }
                </div>

              </div>
            </div>
            {Step === 0 &&
              <div className="d-flex justify-content-end " >
                <button type="button" onClick={SearchForCards} className="btn btn-primary" disabled={!data.type || !data.price || !data.amount || !user_value}>
                  {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Search' })} </span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            }

            {Step === 1 &&
              <>
                <ReactTable columns={columns} data={Cards} customComponent={<></>} />
                <div className="d-flex justify-content-end " >
                  <button type="submit" className="btn btn-primary" disabled={!data.card_id || !data.type || !data.price || !data.amount || !user_value}>
                    {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Orders.Add' })} </span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </>
            }

          </div>
        </form>
      </div>

    </motion.nav>
  )
}
