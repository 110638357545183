import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { App } from '../App'
import Login from '../pages/Auth/Login'
import ForgotPassword from '../pages/Auth/ForgotPassword'
import ResetPassword from '../pages/Auth/ResetPassword'
import EmailVerification from '../pages/Auth/EmailVerification'
import GuestLayout from '../Layout/GuestLayout'
import Verification from '../Layout/Verification'
import AuthLayouy from '../Layout/AuthLayouy'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { AnimatePresence } from 'framer-motion'
import { useLang } from '../../_metronic/i18n/Metronici18n'
import CountriesHome from '../pages/dashboard/Countries/CountriesHome'
import { AddCountries } from '../pages/dashboard/Countries/AddCountries'
import { UpdateCountries } from '../pages/dashboard/Countries/UpdateCountries'
import Profile from '../pages/Auth/Profile'
import UsersHome from '../pages/dashboard/Users/UsersHome'
import { AddUsers } from '../pages/dashboard/Users/AddUsers'
import { UpdateUsers } from '../pages/dashboard/Users/UpdateUsers'
import PendingLayout from '../Layout/PendingLayout'
import PendingRequest from '../pages/PendingRequest/PendingRequest'
import WebDetails from '../pages/Admin/WebDetails/WebDetails'
import BusinessHome from '../pages/Admin/Bussines/BusinessHome'
import AdminLayout from '../Layout/AdminLayout'
import BusinessDetails from '../pages/Owner/BusinessDetails/BusinessDetails'
import CardsHome from '../pages/Owner/Cards/CardsHome'
import { AddCards } from '../pages/Owner/Cards/AddCards'
import { EditCards } from '../pages/Owner/Cards/EditCards'
import AuthContainer from '../pages/Auth/AuthContainer'
import { AddOrder } from '../pages/Owner/Orders/AddOrder'
import { EditOrder } from '../pages/Owner/Orders/EditOrder'
import OrderHome from '../pages/Owner/Orders/OrderHome'
import OwnerUsersHome from '../pages/Owner/OwnerUsers/OwnerUsersHome'
import { AddOwnerUsers } from '../pages/Owner/OwnerUsers/AddOwnerUsers'
import { EditOwnerUsers } from '../pages/Owner/OwnerUsers/EditOwnerUsers'
import MyOrdersHome from '../pages/User/MyOrders/MyOrdersHome'
import { MyOrderDetail } from '../pages/User/MyOrders/MyOrderDetail'
import PhonesHome from '../pages/User/Phones/PhonesHome'
import { AddPhones } from '../pages/User/Phones/AddPhones'
import { EditPhones } from '../pages/User/Phones/EditPhones'
import ExpensesHome from '../pages/Owner/Expenses/ExpensesHome'
import { AddExpenses } from '../pages/Owner/Expenses/AddExpenses'


const AppRoutes = () => {
  const location = useLocation()

  const lang = useLang();
  useEffect(() => {
    if (lang === 'ar') {

      require('../../_metronic/assets/style/style_rtl.css');
      console.log('ar_ar');
    }
    else {
      require('../../_metronic/assets/style/style.css');
      console.log('en_en');
    }
  }, [location])

  function changeFavicon(newIconUrl) {
    var oldIcon = document.querySelector('link[rel="icon"]');
    if (oldIcon) {
      oldIcon.parentNode.removeChild(oldIcon);
    }

    var newIcon = document.createElement('link');
    newIcon.rel = 'icon';
    newIcon.href = newIconUrl;

    document.head.appendChild(newIcon);
  }

  const img = process.env.REACT_APP_IMAGE_PATH + localStorage.getItem('web_img');
  useEffect(() => {
    changeFavicon(img);
    document.title = localStorage.getItem('web_name');
    document.description = localStorage.getItem('web_name');
  }, []);

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route element={<App />}>
          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route path='error/*' element={<ErrorsPage />} />


          <Route element={<GuestLayout />}>
            <Route element={<AuthContainer />}>
              <Route path='/login' element={<Login />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path="/password-reset/:token" element={<ResetPassword />} />
            </Route>
          </Route>

          <Route element={<PendingLayout />}>
            <Route path='/pending-request' element={<PendingRequest />} />
          </Route>

          <Route element={<Verification />}>
            <Route path='/Email-Verification' element={<EmailVerification />} />
          </Route>

          <Route element={<AuthLayouy />}>
            <Route element={<MasterLayout />}>
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='profile' element={<Profile />} />

              {/* Admin Layout */}
              <Route element={<AdminLayout />}>
                {/* WebDetails */}
                <Route path='WebDetails/WebSiteDetails' element={<WebDetails />} />

                {/* Bussines */}
                <Route path='Business' element={<BusinessHome />} />

                {/* Users */}
                <Route path='Users/Users' element={<UsersHome />} />
                <Route path='Users/Add-Users' element={<AddUsers />} />
                <Route path='Users/Edit-Users/:id' element={<UpdateUsers />} />
              </Route>



              <Route path='Analytics' element={<DashboardWrapper />} />
              <Route path='BusinessDetails' element={<BusinessDetails />} />
              <Route path='Cards' element={<CardsHome />} />
              <Route path='Add-Cards' element={<AddCards />} />
              <Route path='Edit-Cards/:id' element={<EditCards />} />

              <Route path='Order' element={<OrderHome />} />
              <Route path='Add-Order' element={<AddOrder />} />
              <Route path='Edit-Order/:id' element={<EditOrder />} />


              <Route path='Owner/Users' element={<OwnerUsersHome />} />
              <Route path='Owner/Add-Users' element={<AddOwnerUsers />} />
              <Route path='Owner/Edit-Users/:id' element={<EditOwnerUsers />} />


              {/* User */}
              <Route path='my-orders' element={<MyOrdersHome />} />
              <Route path='my-orders-detail/:id' element={<MyOrderDetail />} />
              

              <Route path='Phones' element={<PhonesHome />} />
              <Route path='Add-Phones' element={<AddPhones />} />
              <Route path='Edit-Phones/:id' element={<EditPhones />} />
            
              <Route path='Expenses' element={<ExpensesHome />} />
              <Route path='Add-Expenses' element={<AddExpenses />} />

            </Route>
          </Route>

        </Route>
      </Routes>
    </AnimatePresence>
    //admin-countries
  )
}

export default AppRoutes