import React, { useEffect, useState } from 'react'
import useAuthContext from '../../../Auth/AuthContext'
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';

const BusinessDetails = () => {
    const { user, getUser } = useAuthContext();
    const intl = useIntl()
    const [data, setData] = useState({
        balance: 0,
        cash_balance: 0,
        day_send_balance: 0,
        day_receive_balance: 0,
        month_send_balance: 0,
        month_receive_balance: 0,
    })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        try {
            await axiosInstance.post(`/owner-business`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                getUser()
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }

    //owner-business
    const getData = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/owner-business')
        setData({
            balance: data.balance,
            cash_balance: data.cash_balance,
            day_send_balance: data.day_send_balance,
            day_receive_balance: data.day_receive_balance,
            month_send_balance: data.month_send_balance,
            month_receive_balance: data.month_receive_balance,
            id : data.id,
        })
        setLoading(false)
    }


    useEffect(() => {
        getData()
    }, [])
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
        >
            <div className="mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        {intl.formatMessage({ id: 'Menu.BusinessDetails' })}
                    </h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.Home' })}
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.BusinessDetails' })}
                        </li>
                    </ul>
                </div>
            </div>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row flex-between mb-3">


                                    {/* balance */}
                                    <div className=" col-6 mb-3">
                                        <div className="mb-3 fv-row fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.balance' })} </label>
                                            <input type="number" name="balance" className="form-control mb-2" value={data.balance} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.balance' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.balance &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.balance}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {/* cash_balance */}
                                    <div className=" col-6 mb-3">
                                        <div className="mb-3 fv-row fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.cashbalance' })} </label>
                                            <input type="number" name="cash_balance" className="form-control mb-2" value={data.cash_balance} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.cashbalance' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.cash_balance &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.cash_balance}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* daysendbalance */}
                                    <div className=" col-6 mb-3">
                                        <div className="mb-3 fv-row fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.daysendbalance' })} </label>
                                            <input type="number" name="day_send_balance" className="form-control mb-2" value={data.day_send_balance} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.daysendbalance' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.day_send_balance &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.day_send_balance}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* dayreceivebalance */}
                                    <div className=" col-6 mb-3">
                                        <div className="mb-3 fv-row fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.dayreceivebalance' })} </label>
                                            <input type="number" name="day_receive_balance" className="form-control mb-2" value={data.day_receive_balance} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.dayreceivebalance' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.day_receive_balance &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.day_receive_balance}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* monthsendbalance */}
                                    <div className=" col-6 mb-3">
                                        <div className="mb-3 fv-row fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.monthsendbalance' })} </label>
                                            <input type="number" name="month_send_balance" className="form-control mb-2" value={data.month_send_balance} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.monthsendbalance' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.month_send_balance &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.month_send_balance}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* monthreceivebalance */}
                                    <div className=" col-6 mb-3">
                                        <div className="mb-3 fv-row fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.monthreceivebalance' })} </label>
                                            <input type="number" name="month_receive_balance" className="form-control mb-2" value={data.month_receive_balance} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.monthreceivebalance' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.month_receive_balance &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.month_receive_balance}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end ">
                            <button type="submit" className="btn btn-primary">
                                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </motion.nav>

    )
}

export default BusinessDetails