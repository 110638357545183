/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import AdminDashboard from './Dashboard/AdminDashboard'
import useAuthContext from '../../Auth/AuthContext'
import OwnerDashboard from '../Owner/OwnerDashboard/OwnerDashboard'
import { Navigate } from 'react-router-dom'


const DashboardWrapper = () => {
  const {user} = useAuthContext()

  if(user.type === 'admin') return <AdminDashboard />
  if(user.type === 'owner') return <OwnerDashboard />
  if(user.type === 'user') return <Navigate to='/my-orders' />
}

export default DashboardWrapper