import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { Skeleton } from '@mui/material';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images';

const BusinessHome = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])

    const getData = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/admin-business')
        setItems(data)
        setLoading(false)
    }

    const edit_status = async (value, id) => {
        let endpoint;
        endpoint = `/admin-business-status/${id}/${value}`;
        try {
            await axiosInstance.get(endpoint);
            getData()
        } catch (error) {
            console.error(error);
        }
    }

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }),
            accessor: 'id',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    # {row.original.id}
                </div>
            )
        },
      
        {
            Header: intl.formatMessage({ id: 'Table.User' }),
            accessor: row => `${row.user?.name} ${row.user?.id}`,
            Cell: row => (
                row.row.original.user &&
                <div className='d-flex align-items-center ps-3'>
                    <Images img={row.row.original.user.img} name={row.row.original.user.name} />
                    <div className='flex-grow-1'>
                        <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                            {row.row.original.user.name}
                        </div>
                        <span className='text-muted d-block fw-semibold text-start'>#{row.row.original.user.id}</span>
                    </div>
                </div>
            ),
            // Define a custom filter function
            filter: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const user = row.values[id]; // id will be 'user' which is the accessor
                    return (
                        user.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
                    );
                });
            }
        },
        {
            Header: 'enable',
            accessor: 'status',
            Cell: row => (
                <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        value={row.row.original.status}
                        name='notifications'
                        defaultChecked={row.row.original.status}
                        onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
                    />
                </div>
            ),
        },

    ];

    useEffect(() => {
        getData()
    }, [])
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
        >
            <div className="mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        {intl.formatMessage({ id: 'Menu.Business' })}
                    </h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.Home' })}
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.Business' })}
                        </li>
                    </ul>
                </div>
            </div>
            {items.length > 0 && !loading ?
                <ReactTable columns={columns} data={items} customComponent={<></>} />
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }
        </motion.nav>

    )
}

export default BusinessHome