import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import useAuthContext from '../../../../../app/Auth/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'

export const UserSidebar = () => {
  const intl = useIntl()

  return (
    <div>
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Application' })}  </span>
        </div>
      </div>

     
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.MyOrders' })}
        to='/my-orders'
        icon='/media/icons/duotune/general/gen002.svg'
        fontIcon='bi-layers'
      />

    </div>
  )
}
