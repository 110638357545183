import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';
import { MdCloudUpload } from 'react-icons/md';
import OwnerCardsAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/OwnerCardsAutoComplete';

export const EditPhones = () => {
    const { id } = useParams();
    const intl = useIntl()
    const [Sim1, setSim1] = useState(null);
    const [Sim2, setSim2] = useState(null);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    const [data, setData] = useState({
        name: null,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        formData.append('_method', 'PATCH');

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        if(Sim1) formData.append('card1', Sim1.id);
        if(Sim2) formData.append('card2', Sim2.id);
        try {
            await axiosInstance.post(`/user-phones/${id}`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                navigate(-1)
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }
    const get_data = async () => {
        await axiosInstance.get(`/user-phones/${id}`).then(function (res) {
          const response = res.data;
          setData({
            name: response.name,
          });
          setSim1(response.card1);
          setSim2(response.card2);
        })
      }
    
    
      useEffect(() => {
        get_data()
      }, [])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Phones.Edit' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Phones' })}</li>
                    </ul>
                </div>
                <div>
                    <button onClick={() => navigate(-1)} type='button' className='btn btn-primary' >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'Form.GoBack' })}
                    </button>
                </div>
            </div>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">

                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div className="row">


                                    <div className="mb-3 col-12 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}  </label>
                                        <input type="text" name="name" className="form-control py-4" value={data.name} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Name' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.name &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.name}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="my-3 fv-row col-6 fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Sim1' })}</label>
                                        <OwnerCardsAutoComplete value={Sim1} setValue={setSim1} />
                                        {errors.sim1 &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.sim1}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="my-3 fv-row col-6 fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Sim2' })}</label>
                                        <OwnerCardsAutoComplete value={Sim2} setValue={setSim2} />
                                        {errors.sim2 &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.sim2}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="d-flex justify-content-end ">
                            <button type="submit" className="btn btn-primary">
                                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>

                    </div>
                </form>
            </div>

        </motion.nav>
    )
}
