import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../api/axios';
import { motion } from 'framer-motion';
import { KTSVG } from '../../../../_metronic/helpers';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { FormControl, MenuItem, Select, Skeleton } from '@mui/material';
import moment from 'moment';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { MdCloudUpload } from 'react-icons/md';

export const MyOrderDetail = () => {
  const { id } = useParams();
  const intl = useIntl();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [send_data, setSend_Data] = useState({
    img: null,
    type: 'pending',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSend_Data((prevData) => ({ ...prevData, [name]: value }));
  };

  const [errors, setErrors] = useState([]);
  const changeHandler_iamge = (event) => {
    const name = event.target.name;
    console.log(name);
    setSend_Data((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
  };

  const types = [
    { id: 'pending', name: 'pending' },
    { id: 'completed', name: 'completed' },
  ]

  const get_data = async () => {
    const { data } = await axiosInstance.get('/user-orders/' + id)
    setData(data)
  }

  const change_status = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    Object.entries(send_data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('_method', 'PATCH');
    try {
      await axiosInstance.post(`/user-orders/${id}`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false)
        setErrors([])
        navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  useEffect(() => {
    get_data()
  }, [])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center  me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.OrderDetails' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Orders' })}</li>
          </ul>
        </div>
        <div className='col-6'>
          <div className='row flex-end'>
            <div className="col-6">
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  onChange={handleChange}
                  value={send_data.type}
                >
                  {types.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-6">
              <button onClick={() => change_status()} type="submit" className="w-100 btn btn-primary py-5">
                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Save' })} </span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {data && !loading ?
        <div className="row">
          <div className="col-6">
            <div className="card my-4">
              <div className='card-body p-9'>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Card_id' })}</label>
                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{data.card.id}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Card_Name' })}</label>
                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{data.card.name}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Card_Number' })}</label>
                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{data.card.number}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Type' })}</label>
                  <div className='col-lg-8'>
                    <div className={`w-100 flex-start ${data.type == 'buy' ? 'text-success' : 'text-danger'}`}>
                      {data.type === 'buy' ? 'Buy USDT , Send Cash' : 'Sell USDT , Recive Cash'}
                      {data.type === 'buy' ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}
                    </div>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Total' })}</label>
                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{data.amount} EGP</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Status' })}</label>
                  <div className='col-lg-8'>
                    {data.status === 'failed' &&
                      <span className="badge badge-light-danger">{data.status}</span>
                    }
                    {data.status === 'pending' &&
                      <span className="badge badge-light-warning">{data.status}</span>
                    }
                    {data.status === 'completed' &&
                      <span className="badge badge-light-success">{data.status}</span>
                    }
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Date' })}</label>
                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card card-flush py-4 my-4">
              <div className="card-body text-center">
                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" style={{ width: '100%', height: '270px' }} data-kt-image-input="true">
                  <div className="file" style={{ width: '100%', height: '100%' }}>
                    <form onClick={() => document.querySelector(".input-field2").click()} style={{ width: '100%', height: '100%' }}>
                      <input type="file" accept='image/*' className='input-field2' name='img' hidden
                        onChange={changeHandler_iamge}
                      />
                      {send_data.img ?
                        <img src={URL.createObjectURL(send_data.img)} width={'100%'} height={'100%'} alt='' />
                        :
                        <>
                          <MdCloudUpload color='#1475cf' size={60} />
                          <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                        </>
                      }
                    </form>
                    {errors.img &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.img}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }

    </motion.nav>
  )
}
