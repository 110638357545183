import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import useAuthContext from '../../../../../app/Auth/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'

export const OwnerSidebar = () => {
  const intl = useIntl()

  return (
    <div>
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Application' })}  </span>
        </div>
      </div>
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Analytics' })}
        to='/Analytics'
        icon='/media/icons/duotune/graphs/gra004.svg'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.BusinessDetails' })}
        to='/BusinessDetails'
        icon='/media/icons/duotune/general/gen001.svg'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Cards' })}
        to='/Cards'
        icon='/media/icons/duotune/finance/fin008.svg'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Expenses' })}
        to='/Expenses'
        icon='/media/icons/duotune/communication/com009.svg'
        fontIcon='bi-layers'
      />


      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.MyOrders' })}
        to='/my-orders'
        icon='/media/icons/duotune/general/gen002.svg'
        fontIcon='bi-layers'
      />



      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Orders' })}
        to='/Order'
        icon='/media/icons/duotune/abstract/abs015.svg'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Phones' })}
        to='/Phones'
        icon='/media/icons/duotune/electronics/elc003.svg'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Users' })}
        to='/Owner/Users'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-layers'
      />


    </div>
  )
}
