import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { Skeleton } from '@mui/material';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuthContext from '../../../Auth/AuthContext';

const PhonesHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const getData = async () => {
    setLoading(true)
    const { data } = await axiosInstance.get('/user-phones')
    setItems(data)
    setLoading(false)
  }

  const CustomDiv = () => (
    <div className="row flex-end w-100">
      <div className="col-auto">
        <Link to='/Add-Phones' type='button' className='btn btn-primary' >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Phones.Create' })}
        </Link>
      </div>

    </div>
  );

  
  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const { data } = await axiosInstance.delete(`/user-phones/${id}`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`
              Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });

  }

 

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.Name' }),
      accessor: 'name',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
           {row.original.name}
        </div>
      )
    },
    {
        Header: intl.formatMessage({ id: 'Table.Sim1' }),
        accessor: row => `${row.card1?.name} ${row.card1?.number}`,
        Cell: row => (
            row.row.original.card1 ?
            <div className='d-flex align-items-center ps-3'>
                <Images img={row.row.original.card1.img} name={row.row.original.card1.code} type='phone' />
                <div className='flex-grow-1'>
                    <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                        {row.row.original.card1.name}
                    </div>
                    <span className='text-muted d-block fw-semibold text-start'>{row.row.original.card1.number}</span>
                </div>
            </div>
            :
            '---'
        ),
        filter: (rows, id, filterValue) => {
            return rows.filter(row => {
                const card1 = row.values[id];
                return (
                    card1.toLowerCase().includes(filterValue.toLowerCase())
                );
            });
        }
    },
    {
        Header: intl.formatMessage({ id: 'Table.Sim2' }),
        accessor: row => `${row.card2?.name} ${row.card2?.number}`,
        Cell: row => (
            row.row.original.card2 ?
            <div className='d-flex align-items-center ps-3'>
                <Images img={row.row.original.card2.img} name={row.row.original.card2.code} type='phone' />
                <div className='flex-grow-1'>
                    <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                        {row.row.original.card2.name}
                    </div>
                    <span className='text-muted d-block fw-semibold text-start'>{row.row.original.card2.number}</span>
                </div>
            </div>
            :
            '---'
        ),
        filter: (rows, id, filterValue) => {
            return rows.filter(row => {
                const card2 = row.values[id];
                return (
                    card2.toLowerCase().includes(filterValue.toLowerCase())
                );
            });
        }
    },
  
    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: row => (
        <div className="d-flex flex-center my-2">
          <Link to={`/Edit-Phones/${row.row.original.id}`} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Table.Edit' })} </Link>
          <button onClick={(e) => Delete(row.row.original.id)} className='btn me-2  w-100 btn-light-danger'> {intl.formatMessage({ id: 'Table.Delete' })}  </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getData()
  }, [])

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className="mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
            {intl.formatMessage({ id: 'Menu.Phones' })}
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Home' })}
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Phones' })}
            </li>
          </ul>
        </div>
      </div>
      {!loading ?
        <ReactTable columns={columns} data={items} customComponent={<CustomDiv />} />
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }
    </motion.nav>

  )
}

export default PhonesHome