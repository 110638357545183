import { Navigate, Outlet } from "react-router-dom"
import logo from '../../_metronic/assets/Img/Logo/logo.png'

import useAuthContext from "../Auth/AuthContext"
import { Loading } from "../pages/LoadingPage/Loading"

const AdminLayout = () => {
  const { user } = useAuthContext()
  return user !== 0 ?
    !user ?
      <Loading />
      :
      user.type === 'admin' ?
        <Outlet />
        :
        !user.business || user.business.status !== 1 ?
          <Navigate to='/pending-request' />
          :
        <Navigate to='/' />
    : <Navigate to='/login' />
}

export default AdminLayout