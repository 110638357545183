import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';
import { MdCloudUpload } from 'react-icons/md';

export const UpdateUsers = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState("No selected file")
  const navigate = useNavigate();
  const { id } = useParams();

  const types = [
    { id: 'admin', name: 'Admin' },
    { id: 'user', name: 'User' },
  ];

  const [data, setData] = useState({
    type: 'user',
    img: null,
    email: null,
    name: null,
    phone: null,
    password: null,
    confirm_password: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const changeHandler_iamge = (event) => {
    const name = event.target.name;
    setData((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
    setFileName(event.target.files[0].name)
  };

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('_method', 'PATCH');

    try {
      await axiosInstance.post(`/admin-users/${id}`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false)
        setErrors([])
        navigate(`/Users/Users`)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }
  const get_data = async () => {
    await axiosInstance.get(`/admin-users/${id}`).then(function (res) {
      const response = res.data;
      setData({
        img_name: response.img,
        img: null,
        type: response.type,
        name: response.name,
        phone: response.phone,
        email: response.email,
        password: null,
        confirm_password: null,
      });
    })
  }

  useEffect(() => {
    get_data()
  }, [])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'User.Edit' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Users' })}</li>
          </ul>
        </div>
        <div>
          <Link to='/Users/Users' type='button' className='btn btn-primary' >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Form.GoBack' })}
          </Link>
        </div>
      </div>
      <div id="kt_app_content_container" className="app-container container-xxl">
        <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
          <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.ImageContent' })} </h2>
                </div>
              </div>

              <div className="card-body text-center pt-0">
                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                  <div className="file" >
                    <form onClick={() => document.querySelector(".input-field2").click()} >
                      <input type="file" accept='image/*' className='input-field2' name='img' hidden
                        onChange={changeHandler_iamge}
                      />
                      {data.img === null && data.img_name ?
                        <img src={process.env.REACT_APP_IMAGE_PATH + data.img_name} width={150} height={150} alt={fileName} />
                        :
                        data.img ?
                          <img src={URL.createObjectURL(data.img)} width={150} height={150} alt={fileName} />
                          :
                          <>
                            <MdCloudUpload color='#1475cf' size={60} />
                            <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                          </>
                      }
                    </form>
                    {errors.img &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.img}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">

            <div className="card card-flush py-4" data-select2-id="select2-data-132-hyjc">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.Types' })} </h2>
                </div>
              </div>
              <div className="card-body py-0">
                <div className="row flex-between">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{intl.formatMessage({ id: 'Form.Types' })} </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={intl.formatMessage({ id: 'Form.Types' })}
                      name="type"
                      onChange={handleChange}
                      value={data.type}
                    >
                      {types.map((row, key) => (
                        <MenuItem key={key} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.type &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.type}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                </div>
              </div>

              <div className="card-body pt-0">
                <div className="row flex-between mb-3">
                  {/* name */}
                  <div className=" col-6 mb-3">
                    <div className="mb-3 fv-row fv-plugins-icon-container">
                      <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}</label>
                      <input type="text" name="name" className="form-control mb-2" value={data.name} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Name' })} />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      {errors.name &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.name}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>


                  {/* email */}
                  <div className=" col-6 mb-3">
                    <div className="mb-3 fv-row fv-plugins-icon-container">
                      <label className="required form-label"> {intl.formatMessage({ id: 'Form.Email' })} </label>
                      <input disabled type="email" name="email" className="form-control mb-2" value={data.email} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Email' })} />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      {errors.email &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.email}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  {/* name */}
                  <div className=" col-12 mb-3">
                    <div className="mb-3 fv-row fv-plugins-icon-container">
                      <label className="required form-label"> {intl.formatMessage({ id: 'Form.Phone' })}</label>
                      <input type="text" name="phone" className="form-control mb-2" value={data.phone} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Phone' })} />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      {errors.phone &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.phone}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  {/* Password */}
                  <div className=" col-6 mb-3">
                    <div className="mb-3 fv-row fv-plugins-icon-container">
                      <label className="required form-label"> {intl.formatMessage({ id: 'Form.Password' })} </label>
                      <input type="password" name="password" className="form-control mb-2" value={data.password} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Password' })} />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      {errors.password &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.password}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className=" col-6 mb-3">
                    <div className="mb-3 fv-row fv-plugins-icon-container">
                      <label className="required form-label">{intl.formatMessage({ id: 'Form.ConfirmPassword' })} </label>
                      <input type="password" name="confirm_password" className="form-control mb-2" value={data.confirm_password} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.ConfirmPassword' })} />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      {errors.confirm_password &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.confirm_password}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>

              </div>
            </div>



            <div className="d-flex justify-content-end ">
              <button type="submit" className="btn btn-primary">
                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

    </motion.nav>
  )
}
