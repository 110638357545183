import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { Skeleton } from '@mui/material';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ImportCards } from './ImportCards';
import useAuthContext from '../../../Auth/AuthContext';

const CardsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [Import, setImport] = useState(null)
  const { user } = useAuthContext();
  const getData = async () => {
    setLoading(true)
    const { data } = await axiosInstance.get('/owner-cards')
    setItems(data)
    setLoading(false)
  }

  const CustomDiv = () => (
    <div className="row flex-end w-100">
      <div className="col-auto">
        <Link to='/Add-Cards' type='button' className='btn btn-primary' >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Card.Create' })}
        </Link>
      </div>
      <div className="col-auto">
        <button onClick={(e) => setImport(true)} type='button' className='btn btn-light-primary' >
          <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Table.Import' })}
        </button>
      </div>

      <div className="col-auto">
        <button onClick={export_data} type='button' className='btn btn-light-primary' >
          <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Table.Export' })}
        </button>
      </div>
      <div className="col-auto">
        <button onClick={sync_card} type='button' className='btn btn-light-primary' >
          <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Table.Sync' })}
        </button>
      </div>

    </div>
  );

  const export_data = async (event) => {
    try {
      event.preventDefault()
      const response = await axiosInstance.get(`/export-cards`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cards.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response.status === 422) {
      }
    }
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const { data } = await axiosInstance.delete(`/owner-cards/${id}`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`
              Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });

  }
  const sync_card = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const { data } = await axiosInstance.get(`/owner-cards-sync`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`
              Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });

  }
  const formatPercentage = (value, total) => {
    if (total === 0) return "0.00%";
    return ((value / total) * 100).toFixed(2);
  };


  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }),
      accessor: 'id',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          # {row.original.id}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.User' }),
      accessor: row => `${row.name} ${row.number}`,
      Cell: row => (
        row.row.original &&
        <div className='d-flex align-items-center ps-3'>
          <Images img={null} name={row.row.original.code} type='phone' />
          <div className='flex-grow-1'>
            <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
              {row.row.original.name}
            </div>
            <span className='text-muted d-block fw-semibold text-start'>{row.row.original.number}</span>
          </div>
        </div>
      ),
      filter: (rows, number, filterValue) => {
        return rows.filter(row => {
          const user = row.values[number];  
          return (
            user.toLowerCase().includes(filterValue.toLowerCase())  
          );
        });
      }
    },
    {
      Header: intl.formatMessage({ id: 'Table.cashbalance' }),
      accessor: 'balance',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.balance}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Send' }),
      accessor: 'Send',
      Cell: row => (
        <div className=" flex-center w-200" >
          <div className='d-flex align-items-center flex-column mt-3 p-3 bg-primary border-r-8 w-100' >
            <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
              <span> {intl.formatMessage({ id: 'Table.daysendbalance' })} </span>
              <span>
                {formatPercentage(row.row.original.day_send_balance, user.business.day_send_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.day_send_balance, user.business.day_send_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.day_send_balance, user.business.day_send_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.day_send_balance}
              ></div>
            </div>

            <div className=' d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-5 mb-2'>
              <span > {intl.formatMessage({ id: 'Table.monthsendbalance' })} </span>
              <span>
                {formatPercentage(row.row.original.month_send_balance, user.business.month_send_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.month_send_balance, user.business.month_send_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.month_send_balance, user.business.month_send_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.month_send_balance}
              ></div>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.Receive' }),
      accessor: 'Receive',
      Cell: row => (
        <div className=" flex-center w-200" >
          <div className='d-flex align-items-center flex-column mt-3 p-3 bg-danger border-r-8 w-100' >
            <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
              <span> {intl.formatMessage({ id: 'Table.dayreceivebalance' })} </span>
              <span>
                {formatPercentage(row.row.original.day_receive_balance, user.business.day_receive_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.day_receive_balance, user.business.day_receive_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.day_receive_balance, user.business.day_receive_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.day_receive_balance}
              ></div>
            </div>

            <div className=' d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-5 mb-2'>
              <span > {intl.formatMessage({ id: 'Table.monthreceivebalance' })} </span>
              <span>
                {formatPercentage(row.row.original.month_receive_balance, user.business.month_receive_balance)} %
              </span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{ width: formatPercentage(row.row.original.month_receive_balance, user.business.month_receive_balance) + '%' }}
                aria-valuenow={formatPercentage(row.row.original.month_receive_balance, user.business.month_receive_balance)}
                aria-valuemin={0}
                aria-valuemax={user.business.month_receive_balance}
              ></div>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: row => (
        <div className="d-flex flex-center my-2">
          <Link to={`/Edit-Cards/${row.row.original.id}`} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Table.Edit' })} </Link>
          <button onClick={(e) => Delete(row.row.original.id)} className='btn me-2  w-100 btn-light-danger'> {intl.formatMessage({ id: 'Table.Delete' })}  </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getData()
  }, [])

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className="mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
            {intl.formatMessage({ id: 'Menu.Cards' })}
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Home' })}
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Cards' })}
            </li>
          </ul>
        </div>
      </div>
      {!loading ?
        <ReactTable columns={columns} data={items} customComponent={<CustomDiv />} />
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }
      <ImportCards handleClose={setImport} get_data={getData} show={Import} />
    </motion.nav>

  )
}

export default CardsHome